/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";
@import "assets/css/font-awesome.min.css";
@import "~simditor/styles/simditor.css";
@import "assets/css/style.css";

form {
  width: 100%;
}

formly-field {
  display: block;
}

formly-field-captcha {
  img {
    height: 34px;
    position: absolute;
    top: 8px;
    right: 2px;
  }
}

.clear {
  clear: both;
}

.ant-badge-status.release .ant-badge-status-default {
  background-color: #0c66ff;
}

.ant-badge-status.fault .ant-badge-status-default {
  background-color: #22c993;
}

.ant-badge-status.change .ant-badge-status-default {
  background-color: #ffad0d;
}

.username formly-field-input:after {
  content: ' ';
  width: 1.25rem;
  height: 1.25rem;
  display: block;
  position: absolute;
  left: .75rem;
  top: 1rem;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><path d="M50.4 51C40.5 49.1 40 46 40 44v-1.2a18.9 18.9 0 0 0 5.7-8.8h.1c3 0 3.8-6.3 3.8-7.3s.1-4.7-3-4.7C53 4 35.5-3.2 22.3 6c-5.4 0-5.9 8-3.9 16-3.1 0-3 3.8-3 4.7s.7 7.3 3.8 7.3c1 3.6 2.3 6.9 4.7 9v1.2c0 2 .5 5-9.5 6.8S2 62 2 62h60a14.6 14.6 0 0 0-11.6-11z" fill="none" stroke="#94a2b0" stroke-miterlimit="10" stroke-width="2" stroke-linejoin="round" stroke-linecap="round"></path></svg>');
}

.password formly-field-input:after {
  content: ' ';
  width: 1.25rem;
  height: 1.25rem;
  display: block;
  position: absolute;
  left: .75rem;
  top: 1rem;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><path d="M19 30V15A13 13 0 0 1 32 2a13 13 0 0 1 13 13v15" fill="none" stroke="#94a2b0" stroke-miterlimit="10" stroke-width="2"></path><path fill="none" stroke="#94a2b0" stroke-miterlimit="10" stroke-width="2" d="M10 30h44v32H10zm22 11v10"></path></svg>');
}

.captcha formly-field-captcha:before {
  content: ' ';
  width: 1.25rem;
  height: 1.25rem;
  display: block;
  position: absolute;
  left: .75rem;
  top: 1rem;
  z-index: 1;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><rect data-name="layer1" x="2" y="14" width="60" height="36" rx="2" ry="2" fill="none" stroke="#94a2b0" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></rect><path fill="none" stroke="#94a2b0" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 20h4v4h-4z"></path><path data-name="layer1" fill="none" stroke="#94a2b0" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 20h4v4h-4zm10 0h4v4h-4zm10 0h4v4h-4zm10 0h4v4h-4zM10 30h4v4h-4zm0 10h4v4h-4z"></path><path fill="none" stroke="#94a2b0" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 40h34v4H20z"></path><path data-name="layer1" fill="none" stroke="#94a2b0" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 30h4v4h-4zm10 0h4v4h-4zm10 0h4v4h-4zm10 0h4v4h-4z"></path></svg>');
}

.form-control {
  height: auto;
}

.btn-success {
  .ant-progress-circle .ant-progress-text {
    color: #fff;
  }
}

.oops-header .topbar-nav .action a {
  display: inline-block;
  vertical-align: top;
  font-size: .875rem;
  line-height: 1.25rem;
  color: #6d91c1;
  border: 1px solid rgba(109, 145, 193, .5);
  padding: .3125rem 1rem;
  border-radius: 2px;
  margin: 1.25rem 1rem;
}

.oops-main .sidenav .one-link .text {
  white-space: nowrap;
}

.i18n-word {
  white-space: nowrap;
}

.cdk-overlay-container {
  z-index: 1099;
}


nz-formly-field-single-file-component, formly-plupload {
  button.ant-btn {
    width: 250px;
    height: 172px;
    background-color: #f1f9fe;
    border: 1px solid #dbdbdb;
    img {
      margin: 0 -15px;
      max-width: 250px;
      max-height: 170px;
    }
  }
}

a {
  color: #1890ff;
}


.oops-login .login-input-group .ant-input {
  padding: .875rem .75rem .875rem 3.25rem;
  border-color: rgb(206, 212, 218);
  border-radius: 0;
  height: auto;
  line-height: 1.375rem;
}

.has-error nz-form-explain.ant-form-explain {
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545;
}

.ant-input, .ant-select-selection {
  border-radius: 0;
}

app-member-picker {
  .oaui_memberinput_wrapper {
    margin-top: 4px;
    border: 1px solid #d9d9d9;
  }

  .oaui_memberinput_editor {
    min-height: auto;
    padding: 4px 11px;
    line-height: 1.5;

    input {
      height: auto;
    }
  }
}